export const testIdNavigate = 'mockedNavigate';
export const testIdLoader = 'loader';
export const testIdErrorMessage = 'errorMessage';
export const testIdHeader = 'header';
export const testIdSubtitle = 'subtitle';
export const testIdMainTitle = 'mainTitle';
export const testIdToggleLanguageButton = 'toggleLanguageButton';
export const testIdToggleThemeButton = 'toggleThemeButton';
export const testIdLogoutButton = 'logoutButton';
export const testIdLoginButton = 'loginButton';
